import React from 'react';
import { AppBar, Toolbar, Typography  } from '@mui/material';
import logo from './logo_unofficial.png'; // Import your image file
import { Link } from 'react-router-dom'; // Import BrowserRouter, Route, Switch, Link



const NavigationBar = ({ isLoggedIn , projectKey}) => {
  return (
       
    <AppBar position="static" sx={{ backgroundColor: 'black' , height: '40px' }}>
    
      <Toolbar>
      <Link to="/">
      <img src={logo} alt="Forloop Insights" style={{ height: 30, marginTop:-25 }} />
      </Link>
        
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {/*Forloop Insights*/}
        </Typography>
        {isLoggedIn && (
          <nav>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0, display: 'flex', marginTop: -22 }}>
              <li style={{ marginRight: 30 }}>
                <Link to={"/"+projectKey+"/chat"} style={{ color: 'white', textDecoration: 'none' }}>Chat view</Link>
              </li>
              <li style={{ marginRight: 30 }}>
                <Link to={"/"+projectKey+"/product"} style={{ color: 'white', textDecoration: 'none' }}>Product view</Link>
              </li>
              <li style={{ marginRight: 30 }}>
                <Link to={"/"+projectKey+"/retailer"} style={{ color: 'white', textDecoration: 'none' }}>Retailer view</Link>
              </li>
              <li style={{ marginRight: 30 }}>
                <Link to={"/"+projectKey+"/distribution_brand"} style={{ color: 'white', textDecoration: 'none' }}>Distribution view (Brand scope)</Link>
              </li>
              <li style={{ marginRight: 30 }}>
                <Link to={"/"+projectKey+"/distribution_retailer"} style={{ color: 'white', textDecoration: 'none' }}>Distribution view (Retailer scope)</Link>
              </li>
            </ul>
          </nav>
        )}
        
      </Toolbar>
    </AppBar>
  );
}

export { NavigationBar };