import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
} from 'chart.js';
import { NavigationBar } from './NavigationBar';
import ProductView from './ProductView';
import RetailerView from './RetailerView';
import BrandDistributionView from './BrandDistributionView';
import RetailerDistributionView from './RetailerDistributionView';
import ChatView from './ChatView';
import SimpleLogin from './SimpleLogin';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter, Route, Switch, Link

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [projectKey, setProjectKey] = useState(null);

  return (
    <div>
      <Router forceRefresh={true}>
        <div>
          <NavigationBar isLoggedIn={isLoggedIn} projectKey={projectKey} />
          {!isLoggedIn && <SimpleLogin isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setProjectKey={setProjectKey} />}

          <Routes>
            <Route path="/:projectKey/product" element={<ProductView />}>
            </Route>
            <Route path="/:projectKey/retailer" element={<RetailerView />}>
            </Route>
            <Route path="/:projectKey/distribution_brand" element={<BrandDistributionView />}>
            </Route>
            <Route path="/:projectKey/distribution_retailer" element={<RetailerDistributionView />}>
            </Route>
            <Route path="/:projectKey/chat" element={<ChatView />}>
            </Route>
          </Routes>

        </div>
      </Router>
      {
        //<MarketSummaryDashboard></MarketSummaryDashboard>
        //<MainDashboard></MainDashboard>
      }
    </div>
  );
}


export default App;