import React, { useState } from "react";
import axios from "axios";
import "./ChatView.css"; // Optional: You can have specific styling for ChatView

const API_URL = 'https://app.forloop.ai:8001';  // Your FastAPI server URL



const ChatView = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("chatbot");

  const sendMessage = async () => {
    if (!input.trim()) return;

    const newMessages = [...messages, { role: "user", content: input }];
    setMessages(newMessages);
    setLoading(true);

    try {
      let response;

      if (mode === "price_sensitivity") {
        const match = input.match(/([\w\s]+)\s+(\d+)%/);
        if (!match) {
          throw new Error("Invalid format. Use: 'Lindt 10%'");
        }
        const product = match[1].trim();
        const discount = parseFloat(match[2]);

        response = await axios.post(`${API_URL}/price_sensitivity`, {
          products: [product],
          proposed_discount: discount,
        });
      } else {
        response = await axios.post(`${API_URL}/ask`, {
          question: input,
        });
      }

      setMessages([...newMessages, { role: "assistant", content: response.data.response }]);
    } catch (error) {
      console.error("Error fetching response:", error);
      setMessages([...newMessages, { role: "assistant", content: "Error fetching response." }]);
    }

    setLoading(false);
    setInput("");
  };

  return (
    <div className="chat-container">
      <div className="mode-selector">
        <label>Select Mode:</label>
        <select value={mode} onChange={(e) => setMode(e.target.value)}>
          <option value="chatbot">Chatbot Mode</option>
          <option value="price_sensitivity">Price Sensitivity Mode</option>
        </select>
      </div>

      <div className="chat-box">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.role}`}>
            <strong>{msg.role === "user" ? "You" : "Assistant"}:</strong>
            <div dangerouslySetInnerHTML={{ __html: msg.content }} />
          </div>
        ))}
        {loading && <div className="loading">Thinking...</div>}
      </div>

      <div className="input-box">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
          placeholder={mode === "chatbot" ? "Ask anything..." : "Enter product & discount (e.g., Lindt 10%)"}
        />
      </div>
    </div>
  );
};

export default ChatView;